import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source" ]

  copy(event) {
    console.log("clicked")
    let text = this.sourceTarget.innerText
    navigator.clipboard.writeText(text)
    .then(() => {
      alert('Text copied to clipboard');
    })
    .catch(err => {
      alert('Error in copying text: ', err);
    });
  }
}
