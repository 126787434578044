import { Controller } from "stimulus"
import $ from 'jquery';
import 'select2';

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    $('.select2').select2();
  }
}
