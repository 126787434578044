import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["current", "seconds"]

  connect() {
    this.secondsTarget.value = 0
    setInterval(() => {
      this.secondsTarget.value = Number(this.secondsTarget.value) + 1;
    }, 1000)
  }

  click(e) {
    let clicked = e.currentTarget.innerText;
    let current = this.currentTarget.innerText
    if (clicked === current) {
      e.currentTarget.classList.add('bg-secondary')
      this.currentTarget.innerText = String(Number(current) + 1).padStart(2, '0')
      if (current === "99") {
        this.secondsTarget.form.submit()
      }
    }
  }
}
