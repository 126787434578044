import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["submit"]

    connect() {
        console.log("helo")
        console.log(this.submitTarget)
    }

    click(e) {
        console.log("clicked")
        this.submitTarget.click()
    }
}
