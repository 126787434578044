import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "switch_1", "switch_2" ]

  connect() {
  }

  switch() {
    this.switch_1Target.classList.toggle("d-none")
    this.switch_1Target.querySelectorAll("input").forEach((input) => {
      // if (input.disabled) {
      //   input.disabled = false;
      // } else {
      //   input.disabled = true;
      // }
    })
    this.switch_2Target.classList.toggle("d-none")
    this.switch_2Target.querySelectorAll("input").forEach((input) => {
      // if (input.disabled) {
      //   input.disabled = false;
      // } else {
      //   input.disabled = true;
      // }
    })
  }
}
