import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ["messages", "form"]

  connect() {
    console.log("connect")
    console.log(this.messagesTarget)
    this.channel = consumer.subscriptions.create(
      { channel: "ChatChannel", id: "chat" },
      { received: data => { 
        console.log("received")
        this.messagesTarget.insertAdjacentHTML("beforeend", data);  } }
    )
  }
}
