// from http://www.hubgist.com/groups/4
import { Controller } from "stimulus"
import Sortable from 'sortablejs'
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "id", "columnId" ]

  connect() {
    console.log("connected")
    this.sortable = Sortable.create(this.element, {
      onEnd: (event) => { this.end(event) },
      group: 'shared',
    })
  }

  end(event) {
    //
    let url = this.element.dataset.dragUrl
    let id = event.item.dataset.id
    let position = event.newIndex
    let column_id = event.to.dataset.columnId

    let data = new FormData()
    console.log(column_id)
    data.append("task[position]", position)
    data.append("task[column_id]", column_id)
    Rails.ajax({
      url: url.replace(":id", id),
      type: 'PATCH',
      data: data

    })
  }
}
